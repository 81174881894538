import characterThumb1 from "../../images/nft/photo1.png";
import characterThumb2 from "../../images/nft/photo2.png";
import characterThumb3 from "../../images/nft/photo3.png";
import characterThumb4 from "../../images/nft/photo4.png";
import characterThumb5 from "../../images/nft/photo5.png";
import characterThumb6 from "../../images/nft/photo6.png";
import characterThumb7 from "../../images/nft/photo7.png";
import characterThumb8 from "../../images/nft/photo7.png";

const data = [
  {
    thumb: characterThumb1,
  },
  {
    thumb: characterThumb2,
  },
  {
    thumb: characterThumb3,
  },
  {
    thumb: characterThumb4,
  },
  {
    thumb: characterThumb5,
  },
  {
    thumb: characterThumb6,
  },
  {
    thumb: characterThumb3,
  },
  {
    thumb: characterThumb1,
  },
  {
    thumb: characterThumb5,
  },
  {
    thumb: characterThumb6,
  },
  {
    thumb: characterThumb7,
  },
  {
    thumb: characterThumb8,
  },
];

export default data;
