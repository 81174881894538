
function FB() {
  window.fbAsyncInit = function() {
    window.FB.init({
      xfbml            : true,
      version          : 'v14.0'
    });
  };
  
  (function(d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s); js.id = id;
    js.src = 'https://connect.facebook.net/fr_FR/sdk/xfbml.customerchat.js';
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));
  
  
  return (
    <div className="FB">
  <div id="fb-root"></div>
  <div className="fb-customerchat"
  attribution="install_email"
  attribution_version="biz_inbox"
  page_id="103619191969520">
</div>

    </div>

  );
}

export default FB;