const data = [
  {
    title: "Qui nous sommes ?",
    text: "QLife est un ensemble de serveurs basés au Québec.Nous voulons amener votre expérience à un niveau supérieur",
  },
  {
    title: "Comment je peut rejoindre vos serveurs ?",
    text: `Vous avez simplement à rejoindre notre Discord et notre DashBoard`,
  },
  {
    title: "Combien de joueurs avez-vous par soirée ?",
    text: "Nous sommes présentement stables à une 10e mais nous visons beaucoup plus",
  },
  {
    title: "Avez-vous un âge minimum pour vous rejoindre ?",
    text: "Nous acceptons n'importe quel âge",
  },
  {
    title: "Avez-vous de l'admin abuse ?",
    text: "Dans le meilleur des mondes, je vous dirais qu'absolument pas, mais si vous avez un membre du staff, qu'il a abusé de ses fonctions envers vous, il a un lien que vous pouvez contacter la propriétaire et lui dire ce qui sait passer. Il est à noter que dans le Formulaire, la seule personne à le recevoir est la propriétaire.",
  },
  {
    title: "Puis-je postée pour devenir staff ?",
    text: "Bien sûr, vous pouvez postuler à partir de ce site. Nous vous contacterons si besoin :)",
  },
];

export default data;
