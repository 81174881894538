export const footerLinksV1 = [
  {
    title: "Acceuil",
    url: "/",
  },
  {
    title: "À Propos",
    url: "#about",
  },
/*   {
    title: "Roadmap",
    url: "#roadmap",
  }, */
  {
    title: "Team",
    url: "#team",
  },
  {
    title: "Blog",
    url: "https:www.blog.qliferoleplay.com/",
  },
  {
    title: "FAQ",
    url: "#faq",
  },
];
