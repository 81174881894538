import React,{useState,useEffect} from "react";
import Counter from "../../../common/counter";
import bgShape1 from "../../../assets/images/icon/square_shape_1.png";
import bgShape2 from "../../../assets/images/icon/square_shape_2.png";

import CoinInfoCounterWrapper from "./Counter.style";
/* import coinInfoCounterData from "../../../assets/data/coinInfoCounter.json"; */
/* import fivem  from 'fivem-server-info'; */
import  axios from 'axios';
import { wait } from "@testing-library/user-event/dist/utils";


const CoinInfoCounter = () => {
  const [connectedPlayer,SetConnectPlayer] = useState(Array)
  const [connectedMaxPlayer,SetConnectMaxPlayer] = useState(Array)
  const [connectedPlayerDiscord,SetConnectPlayerDiscord] = useState(Array)
  const [DiscordMaxUsers,setDiscordMaxUsers] = useState(Array)

if (connectedPlayerDiscord?.[0]){
 
  wait(3000).then(SetConnectPlayerDiscord(connectedPlayerDiscord[0].approximate_member_count))
  wait(3000).then(setDiscordMaxUsers(connectedPlayerDiscord[0].approximate_presence_count))
  console.log(connectedPlayerDiscord)
}
  const { info } = { 
    "info": [
        {
            "text": "Joueurs en Ligne",
            "number": connectedPlayer,
            "unit": "/"+connectedMaxPlayer
        },
        {
            "text": "Nombre D'Heures Jouée",
            "number": "80000",
            "unit": ""
        }, 
        {
            "text": "Membres Discord",
            "number": connectedPlayerDiscord
            ,
            "unit": ""
        }, 
        {
            "text": "Discord Membre Online",
            "number": DiscordMaxUsers,
            "unit": ""
        }
    ]
}

  useEffect( () => {
    console.log('LOAD')
    axios({data: {},method: 'get',url: `https://discord.com/api/v9/invites/nJsrj2FRVS?with_counts=true&with_expiration=false`}).then((connectedPlayerDiscord2)=> SetConnectPlayerDiscord(Object.values(connectedPlayerDiscord2)));


 /*   fivem.connected_users("server1.qliferoleplay.ca","30123").then((connectedPlayer)=> SetConnectPlayer(connectedPlayer));
   fivem.max_users("server1.qliferoleplay.ca","30123").then((connectedMaxPlayer)=>console.log('MAX',connectedMaxPlayer)); */

 /*    const list =  fivem.user_list("server1.qliferoleplay.ca","30123"); */
    axios.get("https://server1.qliferoleplay.ca/info.json").then((response) => {SetConnectMaxPlayer(response?.data?.vars?.sv_maxClients)});

    axios.get("https://server1.qliferoleplay.ca/players.json").then((response) => {
      const connected_user = response?.data?.length 
/*       const connected = response?.data?.filter(item => item.id === 'movie');
const moviesCount = connected.length; */
  /*   console.log('REP',connected_user) */
    SetConnectPlayer(connected_user)
  })

/*     console.log('LIST',list) */
		return () => {};
	}, [SetConnectPlayer,SetConnectPlayerDiscord,connectedPlayerDiscord,connectedPlayer]);


  return (
    <CoinInfoCounterWrapper>
      <div className="container">
        <ul>         
          {info?.map((item, i) => (
            <li key={i}>  
              <h3>     
                <Counter
                  end={item.number}
                  decimal="."
                  decimals={item.number % 1 !== 0 ? "2" : "0"}
                  suffix={item.unit}
                />
              </h3>
              <h4>{item.text}</h4>
            </li>
          ))}
        </ul>
      </div>
      <img
        src={bgShape2}
        className="bg-shape shape-left shape-top"
        alt="bithu bg shape"
      />
      <img
        src={bgShape1}
        className="bg-shape shape-left shape-bottom"
        alt="bithu bg shape"
      />
      <img
        src={bgShape2}
        className="bg-shape shape-right shape-top"
        alt="bithu bg shape"
      />
      <img
        src={bgShape1}
        className="bg-shape shape-right shape-bottom"
        alt="bithu bg shape"
      />
    </CoinInfoCounterWrapper>
  );
};


export default CoinInfoCounter;
