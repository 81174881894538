import { useModal } from "../../../utils/ModalContext";
import { FiX, FiChevronRight } from "react-icons/fi";
import WalletModalStyleWrapper from "./WalletModal.style";
import hoverShape from "../../../assets/images/icon/hov_shape_L.svg";
import metamaskIcon from "../../../assets/images/icon/MetaMask.svg";
import formatic from "../../../assets/images/icon/Formatic.svg";
/* import trustWalletIcon from "../../../assets/images/icon/Trust_Wallet.svg";
import walletConnect from "../../../assets/images/icon/WalletConnect.svg"; */
const WalletModal = () => {
  const { walletModalHandle } = useModal();
  return (
    <>
      <WalletModalStyleWrapper className="modal_overlay">
        <div
          className="mint_modal_box"
        >
          <div className="mint_modal_content">
            <div className="modal_header">
              <h2>CONNECTION 🛡️QLife</h2>
              <button onClick={() => walletModalHandle()}>
                <FiX />
              </button> 
            </div>
            <div className="modal_body text-center">
              <p>
                S.V.P Choisir le site que vous voulez vous connectée.
              </p>
              <div className="wallet_list">
                <a href="https://portal.qliferoleplay.ca">
                  <img src={metamaskIcon} alt="Meta-mask" />
                  QLife DashBoard
                  <span>
                    <FiChevronRight />
                  </span>
                </a>
                <a href="https://www.blog.qliferoleplay.com/">
                  <img src={formatic} alt="Coinbase" />
                  Blog
                  <span>
                    <FiChevronRight />
                  </span>
                </a>
               {/*  <a href="# ">
                  <img src={trustWalletIcon} alt="Trust" />
                  Trust Wallet
                  <span>
                    <FiChevronRight />
                  </span>
                </a>
                <a href="# ">
                  <img src={walletConnect} alt="Wallet" />
                  WalletConnect
                  <span>
                    <FiChevronRight />
                  </span>
                </a> */}
              </div>
              <div className="modal_bottom_text">
               En vous connectant, vous y acceptée nos
              
                <a href="/ts ">Termes de Services</a>
                <a href="/ts ">Politique de confidentialités</a>
              </div>
            </div>

            <div className="modal_bottom_shape_wrap">
              <span className="modal_bottom_shape shape_left">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
              <span className="modal_bottom_shape shape_right">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
            </div>
          </div>
        </div>
      </WalletModalStyleWrapper>
    </>
  );
};

export default WalletModal;
