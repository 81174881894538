import React, {useState} from 'react';
import './sugg.css';
import Alert from 'react-bootstrap/Alert';
import logo from './img/Logomainside.png'; // with importé
import { Button,Form,Spinner } from 'react-bootstrap';
import { Container } from 'react-bootstrap';

function Suggestion(){

      //DISCORD SEND DATA
   const [description, setDescription] = useState();
   const [formulaire, /* SetFormulaire */] = useState("grid grid-flow-col grid-cols-1 grid-rows-1 gap-4");
   const [lname, setLname] = useState("");
 
   const [discord, setDiscord] = useState("")

   const [label, setLabel] = useState("ENVOYÉE");
  
   const [validated, setValidated] = useState('false');
   const [serv, setServ] = useState();
   const [alert, setShowAlert] = useState(false);
   const [success, setShowSuccess] = useState(false);
   const [required] =useState(true);
 





   async function sendContact(ev) {
    ev.preventDefault(); 

    const webhookBody = {
      content: "Hey, Une suggestion à été postée!",
      embeds: [{
        title: 'SUGGESTION',
        url: "https://qliferoleplay.ca/suggestion",
        color: 4784463,
        fields: [
          { name: 'SERVEUR: ', value: serv },
          { name: 'NOM DISCORD: ', value: discord } ,
          { name: 'NOM: ', value: lname } ,
          { name: 'SUGGESTION PROPOSÉE: ', value: description } 
        ],
        footer: {
                    // eslint-disable-next-line
          text: "Merci, " + "" + lname,
        },
      }],
      
    };
    
    const webhookUrl = 'https://discord.com/api/webhooks/993343727152140310/F31pSBqSmm1ZBVmIPdCkGEJ8G4CQYlVk7stzeVVbwXalFGwAhy0V00lF-Gw5AetGkbyA';

    const response = await fetch(webhookUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(webhookBody),
    });

    if (response.ok) {
      window.alert('Le message devrait avoir été postée!');
      setShowSuccess(true);
    } else {
      setLabel("Erreur, ressayée");
      setShowAlert(true);
    }
  }
    const handleSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
  
      setValidated(true);
    };
    return (
      <Container fluid="xxxl" >
      <div id='suggestion'>
      
     <div className='h-screen flex flex-col justify-center p-60' id="formulaire-sugg">
  
              <div className="text-center h-auto" id="form-sugg">
              <h3>SUGGESTION À NOUS FAIRE</h3>
                   <div id="form-text">
     
                   PARLEZ-NOUS DE VOTRE IDÉE
                   <br></br>
                   <Alert show={alert} variant="danger" onClose={() => setShowAlert(false)} >
        <Alert.Heading>Oh snap! Vous avez une erreur!</Alert.Heading>
        <img id="logo-main" src={logo}  width="200" alt="Logo Thing main logo"/>
     
        <p>
          Remplissée toute les cases et ressayée
        </p>
        <Button onClick={() => setShowAlert(false)}>Parfait, je corrige?</Button>   <Spinner animation="border" role="status">
       <span className="visually-hidden"></span>
      </Spinner>
      </Alert>

      <Alert show={success} variant="success" onClose={() => setShowSuccess(false)} >
        <Alert.Heading>Oh yeah! Vous avez envoyée votre suggestion!</Alert.Heading>
        <img id="logo-main" src={logo}  width="200" alt="Logo Thing main logo"/>
     
        <p>
          Vous devriez voir votre suggestion dans le discord sous peu.
        </p>
        <Button onClick={() => {setShowAlert(false); window.location.reload(); }  }>Parfait, j'adore!</Button>   <Spinner animation="border" role="status">
       <span className="visually-hidden"></span>
      </Spinner>
      </Alert>
                   </div>
                   <form className={formulaire} noValidate validated={validated} onSubmit={handleSubmit}>
                          <label className="serv-select" htmlFor="serv">Serveur Concernée</label>
                          <h3>Serveur Choisi: {serv}</h3>
                          <input
              tabIndex={-1}
              autoComplete="off"
              style={{ opacity: 0, height: 0 }}
              value={serv}
              required={required}
            />
                          <Form.Select aria-label="Default select example" id="selec-serv" value={serv} onChange={(e) => setServ(e.target.value)} >
                         <option name="serv" required>Choisir un serveur</option>
                         <option value="QLife">QLife</option>
                         <option value="QLife N-Y">QLife N-Y</option>
                         <option value="QLife Zombie">QLife Zombie</option>
                         <option value="QLife Extinction">QLife Extinction</option>
                         <option value="QLife Minecraft">QLife Minecraft</option>
                         <option value="QLife FivePD">QLife FivePD</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                        S.V.P choisir un serveur.
                       </Form.Control.Feedback>
                             <label className="descrition" htmlFor ="description">Description de l'idée</label>
                             <textarea value={description} onChange={e => setDescription(e.target.value)} type="text" placeholder="Description de l'idée" className='p-4 rounded' row="3" required />
                             <label className="discordd" htmlFor ="discord">Contact Discord</label>
                             <input value={discord} onChange={e => setDiscord(e.target.value)} type="text" placeholder='UserName ici#xxxx' className='p-4 rounded' rows="3"required />
                             <label className="contact" htmlFor ="contact">Nom du Contact</label>
                             <input value={lname} onChange={e => setLname(e.target.value)} type="text" placeholder="Exa. Yvan santigos" className='p-4 rounded' row="3" required />
                     <Button onClick={sendContact} type="submit" id="BNT-button">{label}</Button>
                   </form>
              </div>
          </div>
          <br></br><br></br>
  </div>
  </Container>
    )
}
 
export default Suggestion;

