import React from 'react';
import { Link } from 'react-router-dom';
import './404.css';
/* import { BrowserRouter  as Router, Route, Link, NavLink } from 'react-router-dom'; */
/* import $ from 'jquery';  */
import logo from '../images/Logomainside.png';
export default function ErrorPage(){

  /* var parallax = function(e) {
    var windowWidth = $(window).width();
    if (windowWidth < 768) return;
    var halfFieldWidth = $(".parallax").width() / 2,
      halfFieldHeight = $(".parallax").height() / 2,
      fieldPos = $(".parallax").offset(),
      x = e.pageX,
      y = e.pageY - fieldPos.top,
      newX = (x - halfFieldWidth) / 30,
      newY = (y - halfFieldHeight) / 30;
    $('.parallax [class*="wave"]').each(function(index) {
      $(this).css({
        transition: "",
        transform:
          "translate3d(" + index * newX + "px," + index * newY + "px,0px)"
      });
    });
  },
  stopParallax = function() {
    $('.parallax [class*="wave"]').css({
      transform: "translate(0px,0px)",
      transition: "all .7s"
    });
    var $timeout = 0;
    $timeout(function() {
      $('.parallax [class*="wave"]').css("transition", "");
    }, 700);
  };
$(document).ready(function() {
  $(".not-found").on("mousemove", parallax);
  $(".not-found").on("mouseleave", stopParallax);
}); */
let year = new Date().getFullYear();
    return(
        <div className="errorPage" id="errorPage">
        <div className="not-found parallax">
            <div className="sky-bg"></div>
            <div className="wave-7"></div>
            <div className="wave-6"></div>
            <Link className="wave-island" to="/">  
                    <img src="http://res.cloudinary.com/andrewhani/image/upload/v1524501929/404/island.svg" alt="Island"  /* as={NavLink} */ />
                </Link>
            <div className="wave-5"></div>
            <div id="err404" className="wave-lost wrp">
                <span>4</span>
                <span>0</span>
                <span>4</span>
            </div>
            <div className="wave-4"></div>
            <div className="wave-boat">
                <img className="boat" src="http://res.cloudinary.com/andrewhani/image/upload/v1524501894/404/boat.svg" alt="Boat"/>
            </div>
          
            <div className="wave-3"></div>
            <div className="wave-2"></div>
            <div className="wave-1"></div>
            <div className="wave-message">
              <img id="logo404" src={logo} alt='logo'/>
                <p id="lost">Oups.... vous êtes perdu?</p> 
                <p id="lost">Clique sur l'Île...</p> 

                <button id="foot"><button className="button-os"><a href="/"><b>Acceuil</b></a></button></button>
            
              <br></br>   <br></br>       <h4 id="copyright">&#169;ALL RIGHT RESERVED 2020 - {year} {/* <a href="https://kathouqccode.ca/"> */}KathouQC{/* </a>< */}</h4>  <br></br>
         
            </div>
        </div>
        </div>
    );
 } 