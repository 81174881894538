/* import {
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaTelegramPlane,
  FaFacebook,
} from "react-icons/fa"; */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram,
  faDiscord
} from "@fortawesome/free-brands-svg-icons";
import './SocialFollow.css';
/* import openseaIcon from "../images/icon/opensea.svg";
import mediumIcon from "../images/icon/med.svg"; */

export default function SocialFollow() {

  return (  
    <div id="icons">
    <a href="https://www.youtube.com/channel/UCEKzZo4XvMRyBnLKkhXdyWQ"
        className="youtube social">
        <FontAwesomeIcon icon={faYoutube} size="2x" id="fontawsome"/>
      </a>
      <a href="https://www.facebook.com/QLifeGames"
        className="facebook social">
        <FontAwesomeIcon icon={faFacebook} size="2x" id="fontawsome" />
      </a>
      <a href="https://twitter.com/KathouQ" className="twitter social">
        <FontAwesomeIcon icon={faTwitter} size="2x" id="fontawsome" />
      </a>
      <a href="https://www.instagram.com/kathouqc/"
        className="instagram social">
        <FontAwesomeIcon icon={faInstagram} size="2x" id="fontawsome" />
      </a>
      <a href="https://discord.gg/82dyFJPCVa"
        className="discord social">
        <FontAwesomeIcon icon={faDiscord} size="2x" id="fontawsome" />
      </a>
      </div>
   
   );
  }



/* const data = [
  {
    thumb: openseaIcon,
    url: "#",
  },
  {
    icon: <FontAwesomeIcon icon={faYoutube} size="2x" id="fontawsome"/>,
    url: "#",
  },
  {
    icon: <FaLinkedinIn />,
    url: "#",
  },
  {
    icon: <FaFacebook />,
    url: "#",
  },
  {
    icon: <FaInstagram />,
    url: "#",
  },
  {
    icon: <FaTelegramPlane />,
    url: "#",
  },
  {
    thumb: mediumIcon,
    url: "#",
  },
]; */

/* export default data;
 */