import { Routes, Route } from "react-router-dom";
import HomeV1 from "../pages/homeV1";
import HomeV2 from "../pages/homeV2";
import HomeV3 from "../pages/homeV3";
import Blogs from "../pages/blogs";
import BlogDetails from "../pages/blogDetails";
import ErrorPage from '../assets/NoFound/404.js';
import TermesServices from '../common/modal/rules/ts'
import PolitiqueConfidentialitée from '../common/modal/rules/pdc'
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

function App() {
  return (
    <I18nextProvider i18n={i18next}>
    <Routes>
      <Route path="/" element={<HomeV1 />} exact />
      <Route path="/home-two" element={<HomeV2 />} />
      <Route path="/home-three" element={<HomeV3 />} />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/post" element={<BlogDetails />} />
      <Route path="/ts" element={<TermesServices />} />
      <Route path="/pdc" element={<PolitiqueConfidentialitée />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
    </I18nextProvider>
  );
}

export default App;
