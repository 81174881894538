const data = [
    {
        num: "1",
        mintColor: "green",
        text: "Création de votre compte sur le dashboard"
    },
    {
        num: "2",
        mintColor: "blue",
        text: "Création de votre compte sur le blog"
    },
    {
        num: "3",
        mintColor: "yellow",
        text: "Rejoindre le Discord"
    },
    {
        num: "4",
        mintColor: "red",
        text: "Connection au serveur désiré"
    },
]

export default data;