import './form.css';
import { useEffect, useState } from 'react';
import React from 'react';
import TeamStyleWrapper from "../components/section/team/v1/Team.style";
/* import axios from "axios"; */
import { Container } from 'react-bootstrap';
/* var qs = require('qs');  */ 




function ContactPage(){
  const [inputEmail, setEmail] = useState('');
  const [inputMessage, SetinputMessage] = useState('');
  const [inputSubject, SetinputSubject] = useState('');
  const [inputName, SetinputName] = useState('');
  const [isErrorShown, /* SetisErrorShown */] = useState(false);
  const [/* loading */, setLoading] = useState(false);
  const [/* response */ , setResponse] = useState(null);
  const [isFormSubmitted, SetFormSubmitted] = useState(false);
  const [validation] = useState(false);

    useEffect(() => {
      const loadScriptByURL = (id, url, callback) => {
        const isScriptExist = document.getElementById(id);
  
        if (!isScriptExist) {
          var script = document.createElement("script");
          script.type = "text/javascript";
          script.src = url;
          script.id = id;
          script.onload = function () {
            if (callback) callback();
          };
          document.body.appendChild(script);
        }
  
        if (isScriptExist && callback) callback();
      }
  
      // load the script by passing the URL
      loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_SECRET_KEY_CAPACHA}`, function () {
        console.log("Script loaded!");
      });
    }, []);
  // eslint-disable-next-line 
    const handleOnClick = e => {
      e.preventDefault(); 
      setLoading(true);
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(process.env.REACT_APP_SECRET_KEY_CAPACHA, { action: 'submit' }).then(token => {
          submitData(token);
        });
      });
    }


    const submitData = token => {
      
      console.log('recieved FORM')
      // call a backend API to verify reCAPTCHA response
      fetch(`${process.env.REACT_APP_BACKEND_URL}api/auth/verify`, {  
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
          
        },
        body: JSON.stringify({
          "name": inputName,
          "inputEmail": inputEmail,
          "g-recaptcha-response": token 
        }),
      }).then(res => res.json()).then(res =>  {
        console.log('VERFI TEXT NOW');
        setLoading(false);
        setResponse(res);
        validateForm();
      });
    }



    function validateForm() {
      // Retrieving the values of form elements 
      var name = inputName;
      var email = inputEmail;
      var need = inputSubject;
      var msg = inputMessage;
  
      var  nameErr  = true;
      var emailErr = true;
      var msgErr = true;
      var needErr = true;
      // Validate name
     
      if(name === "") {
          printError("nameErr", "Veuillez indiquer votre nom")}
       else {
          printError("nameErr", "");
          nameErr = false;
         }
      
         //CHECK LENGHT
         if(!name === ""){
        }else {
         if (name.length <= 2){
          printError("nameErr", "Votre nom ne peut être que de moins de 2 caractères");
         } 
        }
      
         if(msg === ""){
          printError("msgErr", "Veuillez entrée un message");
         }else {
          if (msg.length <= 10){
            printError("msgErr", "Votre message est trop court(10 lettes minimum)");
          }
          printError("msgErr", "");
          msgErr = false
         }
        
  
      // Validate need
      // eslint-disable-next-line
      var regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;  

      if(need === "") {
          printError("needErr", "Veuillez entrée votre besoin");
          needErr = false;
      } else if(regex.test(need) === true){
        console.log("needok");
     
        printError("needErr", "Veuillez entrée votre besoin");
     
          } else {
            console.log("needok")
              printError("needErr", "");
              needErr = false;
          } 
     
      function ValidateEmail(email) 
      {
        // eslint-disable-next-line
       if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
        {
          return (true)
        }
          printError("needErr", "Votre courriel ne semble pas valide...");
          return (false)
      }
   
      if(email === "") {
        printError("emailErr", "Veuillez entrée votre email");
    } else {
      if(ValidateEmail(email) === true){
        printError("emailErr", "");
        emailErr = false;
      }else {
        printError("emailErr", "Votre courriel ne semble pas valide...");
      }
   
    }    
      // Prevent the form from being submitted if there are any errors
      console.log(nameErr, needErr , emailErr , msgErr)
      if((nameErr || needErr || emailErr || msgErr) === true) {
          return false /* and console.log("FALSE") */;
      } else{
        // eslint-disable-next-line
          return  validation === true, sendContactDiscord() , sendEmail();
      }
  };

  function printError(elemId, hintMsg) {
    document.getElementById(elemId).innerHTML = hintMsg;
  }

/*   //EMAIL TOO
  function sendEmail(){
    var palyload = {
      'command': 'userLogin',
      "email" : inputEmail,
      "password" : inputMessage,
  };
  axios.post('`${process.env.REACT_APP_BACKEND_URL}+api/auth/email`, palyload ).then(
    function(response){
        console.log(response+"yup");
    }
); */

   

  
  //DISCORD SEND DATA

  const [/* Label */, setLabel] = useState("SEND");
  async function sendContactDiscord(e) {

 /*    e.preventDefault(); */
    const webhookBody = {
      content: "Hey, @here https://qliferoleplay.ca à postée un FORM!",
      embeds: [{
        title: 'CONTACT',
        url: "https://qliferoleplay.ca/contact",
        color: 4784463,
        fields: [
          { name: 'SUJET: ', value: inputSubject },
          { name: 'NOM DISCORD: ', value: inputName } ,
          { name: 'EMAIL: ', value: inputEmail  } ,
          { name: 'MESSAGE: ', value: inputMessage } 
        ],
        footer: {
          // eslint-disable-next-line
          text: "Merci, " + "" + inputName,
        },
      }],
      
    };
    
 /*    const emailbody = {
        sujet:inputSubject,
        name:inputName,
        email:inputEmail ,
        message:inputMessage,
    }; */
    const webhookUrl = 'https://discord.com/api/webhooks/992960972333514772/1g2Wfy_ov9SpSuqnMUuvekeSkSbR4rCm8K3XfN30a6IB6_ovn-ePaEGISTYwJtxc9xYI';

    const response = await fetch(webhookUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(webhookBody),
    });
    
  /*  fetch('`${process.env.REACT_APP_BACKEND_URL}+api/auth/email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(emailbody),
    }); */
  


   /*  sendEmail(); */
 
    if (response.ok) {
      window.alert('Le message a été envoyée!');
      SetFormSubmitted(true);
    } else {
      setLabel("Erreur, ressayée");
    }
  }

  const sendEmail = () => {
      
    console.log('recieved FORM')
    // call a backend API to verify reCAPTCHA response
    fetch(`${process.env.REACT_APP_BACKEND_URL}api/auth/email`, {  
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
        
      },
      body: JSON.stringify({
        "sujet":inputSubject,
        "name":inputName,
        "email":inputEmail ,
        "message":inputMessage,
      }),
    }).then(res => res.json()).then(res =>  {
      console.log(res)
/*         console.log('VERFI TEXT NOW');
      console.log('OK') */
    });
  }


    return (
        <TeamStyleWrapper>
      <Container fluid="xxxl" style={{marginTop:'30px'}}>
      <div className="contact-page" id="contact">
        <Container fluid="xxxl" style={{marginTop:'30px'}}>
        <form  className="formsub"/* action="http://localhost:8000/emailSend.php" method="post" onSubmit={(event) => handleSumbit(event)} */>
        <div id="contact-title">
                  <h1>Contactée nous!</h1><br/>

<p>N'hésitée pas a nous contactée! Nous allons vous répondre rapidement.</p>
                  </div>
          <fieldset>
            <label htmlFor="inputName">NOM</label>
            <div className="error" id="nameErr"></div>
            <input onChange={e => SetinputName(e.target.value)} value={inputName} type="text" name="inputName" id="inputName"  />
          
          </fieldset>

          <fieldset>
            <label htmlFor="inputEmail">EMAIL</label>
            <div className="error" id="emailErr"></div>
            <input onChange={e => setEmail(e.target.value)} value={inputEmail} type="email" name="inputEmail" id="inputEmail" />
       
            </fieldset>
          <fieldset>
          <label htmlFor="inputSubject">SUJET</label>
          <div className="error" id="needErr"></div>
           <select onChange={e => SetinputSubject(e.target.value)} value={inputSubject} id="form_need" name="inputSubject" className="form-control" required="required" data-error="S.v.p décrire votre besoin."/*  onClick={this.handleCheckbox} */>
                        <option>Choisir</option>
                        <option value="Demande  information">Demande d'information</option>
                        <option value="Aide">Aide</option>
                        <option value="En savoir plus">En savoir plus</option>
                        <option value="Autre<">Autre</option>
                    </select>   
                    </fieldset>
          <fieldset>
            <label>MESSAGE</label>
            <div className="error" id="msgErr"></div>
            <textarea onChange={e => SetinputMessage(e.target.value)} value={inputMessage} /* onChange={this.handleInput} */ name="inputMessage" id="inputMessage" />
          
          </fieldset>

          {isFormSubmitted && (
            <fieldset>
              <p>Merci de m'avoir contacter, je vous répondera dans les prochain jours.</p>
            </fieldset>
          )}

          {isErrorShown && (
            <fieldset>
              <p>S.v.p Vérifie que tout les cases sont remplit</p>
            </fieldset>
          )}

          <fieldset>
    
          <button id="send" onClick={handleOnClick} className="btn">
              Envoyée 
            </button>
          </fieldset>
        </form><br /><br />
        </Container>
      </div>
      </Container>
      </TeamStyleWrapper>
    )
  }



export default ContactPage;