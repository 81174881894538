import SectionTitle from "../../../../common/sectionTitle";

import AboutInfoCardList from "../aboutInfoCardList";

/* import data from "../../../../assets/data/about/aboutv1";
import aboutThumb1 from "../../../../assets/images/nft/about_us_img1.png";
import aboutThumb2 from "../../../../assets/images/nft/about_us_img2.png"; */
import AboutStyleWrapper from "./About.style";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
/*IMG*/
import "./about.css";
import dlc1 from "./img/dlc.jpg";
import casino from "./img/casino.jpg";
import qlifelogo from "./img/qlife-logo.jpg";
import mapping from "./img/mapping.png";
import illegal from "./img/gang.png";
import phone from "./img/nopicture.png";
import events from "./img/event.png";
import Gouv from "./img/gouv.jpg";
import aiderprochain from "./img/helpp.jpg";

const About = () => {
/*   const { aboutDescription1, aboutDescription2 } = data; */
  return (
    <AboutStyleWrapper id="about">
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-md-12">
            <div className="v1_about_us_right_sect">
              <SectionTitle title="À PROPOS DE QLIFE !" subtitle="NOTRE HISTOIRE " />
              <Container>
              <Row>
        <Col  md="auto" xs={{ order: 1 }}>   <div id="inside-about-title">
        <div id="inside-about-title-inside">
        <h2>Avant tout, QLife est bien plus que un serveur, on est une vrai famille ❤</h2>
        <p>La Vie chez QLife
Que ça soit le Service de Police de la QLife, <br/> de la Sûreté de QLife, du service d'urgence-santé en passant par le service d'incendie,

 <br></br>la vie sur QLife est représentative de la vie au Québec, et ce dans plusieurs de ces aspects.</p>
 <img src={qlifelogo} alt="qlife-img" ></img>
        </div>
     </div></Col>
    
        </Row>
        </Container>
       
        <Row>
       <Col xs={12} md={8}>
       <div id="inside-about">
        <div id="inside-about-text">
        <h1>Nouveaux DLC</h1>
      <p>Tout les nouveaux véhicules des derniers DLC de GTAV ainsi que plusieurs véhicules moddés,
      MLO et mapping à travers la ville, vous saurez y trouver des nouveautés à votre goût. </p>
      <img src={dlc1} alt="dlc-img" ></img>
     </div>
        </div>
        </Col>
        <Col xs={12} md={4}>
        <div id="inside-about-2">
    <div id="inside-about-text-2">
    <h1>Casino Diamond</h1>
     <p>Le casino Diamond avec les activités de roulette, BlackJack, machine a sous et bien d'autres.</p>
     <img src={casino} alt="Casino-img" ></img>
    </div>
        </div>
        </Col>
        </Row>
        <Row>
        <Col xs={12} md={8}>
        <div id="inside-about-3">
        <div id="inside-about-text-3">
         <h1>Mappings</h1>
         <p>Plusieurs ajout de MLO de mapping des maisons gang jusqu’au casino.</p>
         <img src={mapping} alt="Mapping-img" ></img>
        </div>
        </div>
        </Col>
        <Col xs={12} md={4}>
        <div id="inside-about-4">
        <div id="inside-about-text-4">
         <h1>Activitée Illégales</h1>
         <p>Contrôle de territoire, exclusivité sur la revente d'une drogue, vol de maison, <br></br>
          revente de voiture volée ou vol de Banque en passant par des vols d'ATM,vol de camions, <br>
          </br>les activités illégales en villes sont très nombreuses!</p>
          <img src={illegal} alt="Illagal-img" ></img>
        </div>
        </div>
        </Col>
        </Row>

        <Row>
        <Col xs={12} md={8}>
        <div id="inside-about-5">
        <div id="inside-about-text-5">
         <h1>Téléphone Personnalisé</h1>
         <p>Un Téléphones avec des fonctions unique,comme voir ses Véhicules dans les garages et bien plus encore</p>
         <img src={phone} alt="Phone-img" ></img>
        </div>
        </div>
        </Col>
        <Col xs={12} md={4}>
        <div id="inside-about-6">
        <div id="inside-about-text-6">
         <h1>Événements Fréquents</h1>
         <p>Evénements fréquents. Que ce soit des courses de voitures, karting, karaoké ou même survival avec Zombies,<br></br>
         ou meme encore des personnages mystérieux, QLife vous as réserver plusieurs surprises!</p>
         <img src={events} alt="Event-img" ></img>
        </div>
        </div>
        </Col>
        </Row>

        <Row>
        <Col xs={12} md={8}>
        <div id="inside-about-7">
        <div id="inside-about-text-7">
         <h1>GOUVERNEMENT</h1>
         <p>Chaque citoyen fait partie intégrante de cette superbe ville. Les principes égalitaires du gouvernement ne laissent pas place au favoritisme et au dénigrement. Le gouvernement est représenté par l’administration du serveur, Fondateur, Administrateur et Modérateur. <br></br>
          Ceux-ci sont très présents et à l’écoute de la communauté.</p>
          <img src={Gouv} alt="Gouv-img" ></img>
        </div>
        </div>
        </Col>
        <Col xs={12} md={4}>
        <div id="inside-about-8">
        <div id="inside-about-text-8">
         <h1>Aidée sont prochain</h1>
         <p>Ici personne est laissé indifférent,tout les citoyen aide à rendre l'avenir de son prochain meilleurs</p>
         <img src={aiderprochain} alt="Aidep-img" ></img>
       
        </div>
    </div>
        </Col>
        </Row>
        
       
        
        
       
              <div className="v1_about_us_right_text">
               {/*  <p>{aboutDescription1}</p>
                <p>{aboutDescription2}</p>
              </div>
            </div> */}
          </div>
       {/*    <div className="col-md-6">
            <div className="v1_about_us_left_sect sm-mt-60">
              <div className="v1_about_us_img_card v1_about_us_img_card1">
                <div className="v1_about_us_img v1_about_us_img1">
                  <img src={aboutThumb1} alt="img" />
                </div>
                <div className="v1_about_us_img1_shapes">
                  <div className="v1_about_us_img1_shapes_left">
                    <div className="shape_1"></div>
                    <div className="shape_2"></div>
                  </div>
                  <div className="v1_about_us_img1_shapes_right">
                    <div className="shape_3"></div>
                  </div>
                </div>
              </div>
              <div className="v1_about_us_img_card v1_about_us_img_card2">
                <div className="v1_about_us_img v1_about_us_img2">
                  <img src={aboutThumb2} alt="img" />
                </div>
                <div className="v1_about_us_img2_shapes">
                  <div className="v1_about_us_img2_shapes_left">
                    <div className="shape_1"></div>
                    <div className="shape_2"></div>
                  </div>
                  <div className="v1_about_us_img2_shapes_right">
                    <div className="shape_3"></div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
       
        {/* about card  */}
        <SectionTitle title="NOS SERVEURS !" subtitle="NOS SERVEURS " />
        <AboutInfoCardList />
        </div>
        </div>
        </div>
        </div>
    </AboutStyleWrapper>
    
  );
};

export default About;
