import { useModal } from "../../../utils/ModalContext";
/* import { FiX, FiChevronRight } from "react-icons/fi"; */
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
/* import WalletModalStyleWrapper from "../WalletModal.style"; */
import WalletModal from "../../../common/modal/walletModal/WalletModal";
import Footer from "../../../components/section/footer/v1";
import Header from "../../../components/section/header/v1/Header";
import Layout from "../../../common/layout";
import GlobalStyles from "../../../assets/styles/GlobalStyles";
/* import hoverShape from "../../../assets/images/icon/hov_shape_L.svg";
import metamaskIcon from "../../../assets/images/icon/MetaMask.svg";
import formatic from "../../../assets/images/icon/Formatic.svg"; */
/* import trustWalletIcon from "../../../assets/images/icon/Trust_Wallet.svg";
import walletConnect from "../../../assets/images/icon/WalletConnect.svg"; */
const TS = () => {
    const {  walletModalvisibility } = useModal();
  return (

   <Layout>
       <GlobalStyles /> 
      {walletModalvisibility && <WalletModal />}
    
      <Header />
      <Container style={{marginTop:'10%',color:'white'}}>
       <Row>
        <Col>
        
        <h1>Termes de Services</h1>
         <li>Protection de l’Enfance</li>
         <i>Les sites web sont extrêmement faciles d’accès et ne doivent en aucun cas, par leur contenu, choquer les plus jeunes.</i>
          <br></br>
        <li>Contenus manifestement illicites</li>
        <i>Le contenu publié par l’utilisateur ne doit pas inciter à la haine, à la violence, à toutes formes de discriminations ou de comportements interdit par la Loi.</i>
        <br></br>
        <li>Contenus litigieux</li>       
         <i>L’utilisateur comme le visiteur doivent respecter les règles du Blog. Dans le cas contraire, des sanctions peuvent être prises.</i>
         <i>Pour s’assurer du bon fonctionnement de QLife, le créateur doit éditer différents documents qui le protège et informent les utilisateurs du bon comportement à adopter lors de leur visite sur le site.</i>
         <br></br>
        <li>Mentions légales</li>
        <i>Les premières informations à fournir obligatoirement sont les mentions légals.</i>
        <i>On y spécifie l’identité du ou des détenteurs du Blog, le numéro d’immatriculation de la marque si elle a été déclaré à la CNIL (Commission Nationale de l’Informatique et des Libertés), le service d’hébergement, la personne à contacter en cas de litige, etc.</i>
        <br></br>
        <li>Charte d’utilisation de données personnelles</li>
        <i>Ce document n’est pas obligatoire sauf dans le cas où des informations personnelles de l’utilisateur sont collectées (par exemple leur adresse e-mail). Grâce à ce document, le propriétaire du Blog s’engage à ne pas les communiquer à des tiers, sans l’accord de leur titulaire.</i>
        <br></br>
        <li>Conditions générales d’utilisation</li>
        <i>Les Condition Général de utilisation (CGU) décrivent les termes et conditions dans lesquels le Blog fournit un service à l’utilisateur.</i>
        <i>Tout internaute souhaitant accéder au Blog doit avoir pris connaissance préalablement de ces Conditions Générales d’Utilisation et s’engage à les respecter sans réserve.</i>
        <i>Les CGU figurent parmi les documents obligatoires à fournir et de nombreuses mentions sont à incorporer au texte pour éviter tout débordement et par la suite litige.</i>
         <ul>
            <br></br>
            <li>En premier lieu, le Blog doit établir ses droits en tant que propriétaire des données qui y sont publiées :</li>
            <ul>
            <br></br>
                <li>PROPRIETE INTELLECTUELLE</li>
                <br></br>
                <i>La présentation et chacun des éléments présent sur le Blog appartient au créateur. L’utilisateur ne doit en aucun cas copier ou reproduire ces informations.</i>
                <br></br><br></br>
                <li>RESPONSABILITE</li>
                <br></br>
                <i>Le l'utilisateur est seul responsable des informations, textes, images, vidéos, données, fichiers, programmes contenus dans son site. Il doit modérer les éventuels commentaires qui ne respectent pas la Loi.</i>
                <br></br>
                <li>MODIFICATIONS ET JURIDICTION COMPETENTE</li>
                <br></br>
                <i>Le créateur spécifie qu’elle est la juridiction compétente en cas de litige.</i>
                <br></br>
            </ul>
            <li>En second lieu, le site informe l’utilisateur de ses droits et ses devoirs lorsqu’il surfe sur le blog :</li>
            <ul>
                <li>OBLIGATIONS DU VISITEUR</li>
                <br></br>
                <i>Le visiteur doit être informé qu’il est responsable du contenu qu’il diffuse sur Internet notamment à travers ses commentaires.</i>
                <br></br>
                <li>CAS DE NON RESPECT DES CONDITIONS D’UTILISATION</li>
                <br></br>
                <i>Des sanctions peuvent être prises à l’encontre de l’utilisateur en cas de non-respect des consignes.</i>
                <b><i>Les utilisateurs, pensez donc à vous munir de ces documents indispensables si vous voulez être correctement protégés !</i></b>
            </ul>
         </ul>
         <br></br>   <br></br>
         <h1>Cookie</h1>
        <i>Renseignements recueillis à partir de votre utilisation du Site</i>
        <br></br>   <br></br>
        <i>Les cookies sont utilisés sur ce site dans plusieurs contextes. Si vous avez un compte et que vous vous connectez à ce site, nous créerons un cookie temporaire pour vérifier que votre navigateur les accepte. Il ne contient aucune donnée personnelle.</i>
        <br></br>   <br></br>
        <i>Au moment de la connexion, nous allons vous définir un ou plusieurs cookies, appelés « cookies de session », permettant à notre système de retrouver et de personnaliser les informations à vous afficher. Ce cookie dure jusqu’à 1 semaine, et peut persister 1 an si vous le précisez (en cochant « Se souvenir de moi » à la connexion). Si vous vous déconnectez, les cookies de session seront automatiquement supprimés.</i>
        </Col>
       
       </Row>
      </Container>
      <Footer />
    
    </Layout>
   
  );
};

export default TS;